import { graphql } from "gatsby"
import React, { useState } from "react"
import { Container } from "react-bootstrap"
import { PageLinks } from "../common/site/page-static-links"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule"
import MarketReport from "../components/MarketReportDetails/MarketReportDetails"
import SocialShareComponent from "../components/SocialShareComponent/SocialShareComponent"
import Layout from "../components/layout"
import { formTracking } from "../components/Common/utils"
import SEO from "../components/seo"

const MarketReportDetails = ({ data }) => {
  const marketData = data?.strapiMarketReport

  const [shareIcons, setShareIcons] = useState(false)

  const openShareicons = () => {
    setShareIcons(true)
    if (shareIcons === true) {
      setShareIcons(false)
    }
    formTracking({
      event_tracking: "share-property",
      form_name: "Share Property ",
    })
  }

  const pageurl = typeof window !== "undefined" ? window.location.href : ""

  const PageData=data?.allStrapiPage?.edges?.length>0?data?.allStrapiPage?.edges[0]?.node:"";

  let popUpData=""

  if(PageData?.add_page_modules?.length>0){
     const results=PageData?.add_page_modules?.filter(item=>item.__typename==="STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT")
     if(results?.length>0){
      popUpData=results[0]
     }
  }

  return (
    <Layout popUpData={popUpData} dark={true}>
      <div className="layout-padding-top"></div>
      <Container>
        <div className="breadcum-share-wrapper">
          <BreadcrumbModule
            mainparentname={`Resources`}
            mainparent={PageLinks.resources}
            parentname={`Market Reports`}
            type={"details-page"}
            parent={PageLinks.marketreport}
            pagename={marketData?.title}
          />
          <div className="share-news-details" onClick={() => openShareicons()}>
            <i className="icon grey-share" />
            <span>Share</span>
            {shareIcons && (
              <SocialShareComponent
                openShareicons={openShareicons}
                shareurl={pageurl}
              />
            )}
          </div>
        </div>
      </Container>

      <MarketReport marketData={marketData} />
    </Layout>
  )
}

export const Head = ({ data }) => {
  const marketInfo = data?.strapiMarketReport
  const metaTitle = marketInfo?.title;
  const metaDesc = marketInfo?.title;
  return <SEO title={metaTitle} description={metaDesc}
  imageUrl="https://ggfx-handh3.s3.eu-west-2.amazonaws.com/i.prod/hh_logo_on_green_background_93feeb7b8b.png"

  ></SEO>
}

export default MarketReportDetails

export const query = graphql`
  query ($page_id: Int) {
    strapiMarketReport(strapi_id: { eq: $page_id }) {
      title
      content {
        data {
          content
        }
      }
      pdf {
        url
      }
      card_list {
        heading
        items {
          cta_title
          content {
            data {
              content
            }
          }
          custom_link
          link {
            slug
            strapi_parent {
              slug
            }
          }
          image {
            alternativeText
            url
          }
          link_type
        }
      }
    }
    allStrapiPage(filter: {layout: {eq: "market_report"}}) {
      edges {
        node {
          title
          add_page_modules {
            ... on STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT {
              __typename
              ...GlobalPopUpFragment
            }
          }
        }
      }
    }
  }
`
